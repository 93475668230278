import { useTranslation } from 'react-i18next';
import ButtonType from '../../constants/ButtonType.js';
import * as MediaPreviewType from '../../constants/MediaPreviewType.js';
import { getMediaPreviewType } from '../../utils/getMediaPreviewType';
import Button from '../Button/Button';
import MediaRemovePreviewImageButton from '../MediaRemovePreviewImageButton/MediaRemovePreviewImageButton';
import MediaUploadFilePicker from '../MediaUploadFilePicker/MediaUploadFilePicker';
import MediaUploadRemoveButton from '../MediaUploadRemoveButton/MediaUploadRemoveButton';

const getRemoveButton = (
  customImageUpload: boolean,
  canEdit: boolean,
  previewType: number,
): boolean => {
  if (customImageUpload && previewType === MediaPreviewType.File) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.Pdf) {
    return false;
  }

  return Boolean(!customImageUpload && canEdit);
};

const getRemovePreviewButton = (
  customImageUpload: boolean,
  canEdit: boolean,
  previewType: number,
  mediaLinkUrl: string,
  mediaPreviewUrl?: string,
): boolean => {
  if (!canEdit) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.Pdf) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.Image) {
    return false;
  }
  if (customImageUpload && mediaLinkUrl && mediaLinkUrl.endsWith('.pdf')) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.Video) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.EmbedGoogleMaps) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.EmbedPdf) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.EmbedGoogleMaps) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.EmbedSoundcloud) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.EmbedVimeo) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.EmbedYoutubeNoCookie) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.EmbedYoutube) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.EmbedYoutubeNoCookie) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.EmbedYoutubeShort) {
    return false;
  }
  if (customImageUpload && previewType === MediaPreviewType.File && !mediaPreviewUrl) {
    return false;
  }
  return true;
};

function MediaUploadPreview({
  media,
  handleFileDelete,
  canEdit,
  mediaLinkUrl,
  mediaPreviewUrl,
  MediaPreviewContainer,
  customImageUpload,
  handlePreviewFileSelect,
  handlePreviewFileDelete,
}: {
  media: string;
  handleFileDelete: () => void;
  canEdit: boolean;
  mediaLinkUrl?: string;
  mediaPreviewUrl?: string;
  MediaPreviewContainer: any;
  customImageUpload?: boolean;
  handlePreviewFileSelect: ({ file }: { file: File }) => void;
  handlePreviewFileDelete: () => void;
}) {
  const hasMedia = mediaPreviewUrl || media;
  const showFilePicker = customImageUpload && !hasMedia;
  const previewType = getMediaPreviewType(mediaPreviewUrl || media, '', customImageUpload);
  const showRemoveButton = getRemoveButton(customImageUpload, canEdit, previewType);
  const showRemovePreviewButton = getRemovePreviewButton(
    customImageUpload,
    canEdit,
    previewType,
    mediaLinkUrl,
    mediaPreviewUrl,
  );
  const showRemoveReference = true;
  const [t] = useTranslation();

  return (
    <div className="flex flex-col bg-bgGray p-3 relative">
      {showFilePicker && (
        <MediaUploadFilePicker handleFileSelect={handlePreviewFileSelect} isImage />
      )}
      {customImageUpload && hasMedia && (
        <div>
          <MediaPreviewContainer
            url={mediaPreviewUrl}
            interactive={false}
            mediaLinkUrl={mediaLinkUrl}
            customImageUpload={customImageUpload}
            minimal={false}
            mediaPreviewUrl={mediaPreviewUrl}
            isUploadPreview
            media={media}
          />
          {showRemovePreviewButton && (
            <MediaRemovePreviewImageButton handleDelete={handlePreviewFileDelete} />
          )}
        </div>
      )}

      <div className="flex flex-col items-center flex-1">
        <div className="flex items-center p-4 w-full contain-content bg-white">
          {!customImageUpload && (
            <MediaPreviewContainer
              url={mediaPreviewUrl}
              interactive={false}
              mediaLinkUrl={mediaLinkUrl}
            />
          )}
          {customImageUpload && (
            <>
              <MediaPreviewContainer
                url={media}
                interactive={false}
                mediaLinkUrl={mediaLinkUrl}
                customImageUpload={customImageUpload}
                minimal
              />
              <div className="flex gap-2 wrap shrink-0">
                {showRemoveReference && (
                  <Button
                    variant={ButtonType.Blue}
                    className="flex flex-0"
                    onClick={handleFileDelete}
                  >
                    {t('common.removeFile')}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {showRemoveButton && (
        <MediaUploadRemoveButton handleDelete={handleFileDelete} previewType={previewType} />
      )}
    </div>
  );
}

export default MediaUploadPreview;
