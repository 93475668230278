import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ActionsStep from './ActionsStep';
import CardAdd from './CardAdd';
import styles from './List.module.scss';
import DroppableTypes from '../../constants/DroppableTypes';
import Flags from '../../constants/Flags';
import DefaultCardContainer from '../../containers/CardContainer';
import { usePopup } from '../../lib/popup';
import AddAnotherCard from '../AddAnotherCard/AddAnotherCard';
import ListHeader from '../ListHeader/ListHeader';

const List = React.memo(
  ({
    id,
    index,
    name,
    isPersisted,
    cardIds,
    canEditList,
    canAddCard,
    onUpdate,
    onDelete,
    onCardCreate,
    CardContainer = DefaultCardContainer,
    theme,
    isMobile,
  }) => {
    const [isAddCardOpened, setIsAddCardOpened] = useState(false);

    const nameEdit = useRef(null);
    const listWrapper = useRef(null);

    const handleHeaderClick = useCallback(() => {
      if (isPersisted && canEditList) {
        nameEdit.current.open();
      }
    }, [isPersisted, canEditList]);

    const handleNameUpdate = useCallback(
      (newName) => {
        onUpdate({
          name: newName,
        });
      },
      [onUpdate],
    );

    const handleAddCardClick = useCallback(() => {
      if (Flags.CARD_AUTO_OPEN_MODAL) {
        const autoOpen = true;
        onCardCreate(
          {
            name: '',
          },
          autoOpen,
        );
      } else {
        setIsAddCardOpened(true);
      }
    }, [onCardCreate]);

    const handleAddCardClose = useCallback(() => {
      setIsAddCardOpened(false);
    }, []);

    const handleNameEdit = useCallback(() => {
      nameEdit.current.open();
    }, []);

    const handleCardAdd = useCallback(() => {
      setIsAddCardOpened(true);
    }, []);

    useEffect(() => {
      if (isAddCardOpened) {
        listWrapper.current.scrollTop = listWrapper.current.scrollHeight;
      }
    }, [cardIds, isAddCardOpened]);

    const ActionsPopup = usePopup(ActionsStep);

    const cardsNode = (
      <Droppable
        droppableId={`list:${id}`}
        type={DroppableTypes.CARD}
        isDropDisabled={!isPersisted}
      >
        {({ innerRef, droppableProps, placeholder }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...droppableProps} ref={innerRef}>
            <div className={styles.cards}>
              {cardIds.map((cardId, cardIndex) => (
                <CardContainer key={cardId} id={cardId} index={cardIndex} className="mt-2" />
              ))}
              {placeholder}
              {canAddCard && (
                <CardAdd
                  isOpened={isAddCardOpened}
                  onCreate={onCardCreate}
                  onClose={handleAddCardClose}
                />
              )}
            </div>
          </div>
        )}
      </Droppable>
    );

    return (
      <Draggable
        draggableId={`list:${id}`}
        index={index}
        isDragDisabled={!isPersisted || !canEditList}
      >
        {({ innerRef, draggableProps, dragHandleProps }) => (
          <div
            {...draggableProps} // eslint-disable-line react/jsx-props-no-spreading
            data-drag-scroller
            ref={innerRef}
            className={classNames(styles.innerWrapper, 'mr-4 md:mr-6 flex-1 ')}
            data-test-id="List"
          >
            <div className={classNames(styles.outerWrapper, 'max-h-full flex flex-col')}>
              <ListHeader
                name={name}
                isPersisted={isPersisted}
                canEdit={canEditList}
                onDelete={onDelete}
                dragHandleProps={dragHandleProps}
                handleHeaderClick={handleHeaderClick}
                ActionsPopup={ActionsPopup}
                nameEdit={nameEdit}
                handleNameUpdate={handleNameUpdate}
                handleNameEdit={handleNameEdit}
                handleCardAdd={handleCardAdd}
                isMobile={isMobile}
              />

              <div
                ref={listWrapper}
                className={classNames(
                  styles.cardsInnerWrapper,
                  'mt-4 max-h-full overflow-y-visible',
                )}
                data-test-id="ListWrapper"
              >
                <div className={classNames(styles.cardsOuterWrapper, 'h-full')}>{cardsNode}</div>
              </div>
              {!isAddCardOpened && canAddCard && (
                <AddAnotherCard
                  isPersisted={isPersisted}
                  handleAddCardClick={handleAddCardClick}
                  cardIds={cardIds}
                  background={theme.addButtonBackground}
                />
              )}
            </div>
          </div>
        )}
      </Draggable>
    );
  },
);

List.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  cardIds: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  canEditList: PropTypes.bool.isRequired,
  canAddCard: PropTypes.bool.isRequired,
  scrollable: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCardCreate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  CardContainer: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

export default List;
