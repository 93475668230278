const LOCAL_STORAGE_KEY = 'mika-do-user-hash';

export function getOrCreateUserHash() {
  const storedHash = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (storedHash) {
    return storedHash;
  }

  const newHash = window.crypto.randomUUID();
  localStorage.setItem(LOCAL_STORAGE_KEY, newHash);
  return newHash;
}
