import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Card from '../components/Card';
import Flags from '../constants/Flags.ts';
import * as entryActions from '../entry-actions';
import * as selectors from '../selectors';
import { getFinalMediaPreviewUrl } from '../utils/getFinalMediaPreviewUrl';

const makeMapStateToProps = () => {
  const selectCardById = selectors.makeSelectCardById();
  const selectUsersByCardId = selectors.makeSelectUsersByCardId();

  return (state, { id, index }) => {
    const { projectId } = selectors.selectPath(state);
    const allProjectsToLists = selectors.selectProjectsToListsForCurrentUser(state);
    const allBoardMemberships = selectors.selectMembershipsForCurrentBoard(state);
    const currentUser = selectors.selectCurrentUser(state);
    const {
      name,
      boardId,
      listId,
      isPersisted,
      description,
      embedUrl,
      ogTitle,
      ogDescription,
      ogImage,
      mediaUrl,
      backgroundColor,
      coverUrl,
      scheduledToBeVisibleAt,
      mediaPreviewUrl,
      approved,
      isGuestCard,
    } = selectCardById(state, id);
    const { canEditCard: canEdit } = selectors.selectCardPermissionsForUserAndCardById(
      state,
      id,
      currentUser?.id,
    );
    const users = selectUsersByCardId(state, id);
    const customImageUpload = Flags.CUSTOM_IMAGE_UPLOAD;

    const isCurrentUserMemberOfBoard = allBoardMemberships.some(
      (user) => user.userId === currentUser?.id,
    );
    const canApproveOrRejectCard = !approved && isCurrentUserMemberOfBoard;

    const finalMediaPreviewUrl = getFinalMediaPreviewUrl({
      customImageUpload,
      mediaPreviewUrl,
      coverUrl,
      mediaUrl,
    });

    return {
      id,
      index,
      name,
      description,
      boardId,
      listId,
      projectId,
      isPersisted,
      users,
      allProjectsToLists,
      allBoardMemberships,
      canEdit,
      ogTitle,
      ogDescription,
      ogImage,
      embedUrl,
      backgroundColor,
      mediaUrl,
      mediaPreviewUrl: finalMediaPreviewUrl,
      mediaLinkUrl: embedUrl || mediaUrl,
      scheduledToBeVisible:
        scheduledToBeVisibleAt && Date.now() < Date.parse(scheduledToBeVisibleAt),
      customImageUpload,
      isGuestCard,
      canApproveOrRejectCard,
      approved,
    };
  };
};

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      onUpdate: (data) => entryActions.updateCard(id, data),
      onMove: (listId, index) => entryActions.moveCard(id, listId, index),
      onTransfer: (boardId, listId) => entryActions.transferCard(id, boardId, listId),
      onDelete: () => entryActions.deleteCard(id),
      onReject: () => entryActions.deleteCard(id),
      onBoardFetch: entryActions.fetchBoard,
      openMediaPreviewModal: entryActions.openMediaPreviewModal,
    },
    dispatch,
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(Card);
