import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import List from '../components/List';
import * as entryActions from '../entry-actions';
import * as selectors from '../selectors';
import { selectCanAddCards } from '../selectors';

const makeMapStateToProps = () => {
  const selectListById = selectors.makeSelectListById();
  const selectCardIdsByListId = selectors.makeSelectCardIdsByListId();

  return (state, { id, index }) => {
    const { name, isPersisted } = selectListById(state, id);
    const cardIds = selectCardIdsByListId(state, id);
    const canEditList = selectors.selectCanEditBoard(state);
    const canAddCard = selectors.selectCanAddCards(state);
    const project = selectors.selectCurrentProject(state);
    const theme = selectors.selectProjectTheme(project);
    const { isMobile } = state.root;
    return {
      id,
      index,
      name,
      isPersisted,
      cardIds,
      canEditList,
      canAddCard,
      theme,
      isMobile,
    };
  };
};

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      onUpdate: (data) => entryActions.updateList(id, data),
      onDelete: () => entryActions.deleteList(id),
      onCardCreate: (data, autoOpen) => entryActions.createCard(id, data, autoOpen),
    },
    dispatch,
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(List);
