/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WriteAccessEnum } from '../../constants/WriteAccessTypes';
import SingleSelectComboBox from '../SingleSelectComboBox/SingleSelectComboBox';

type WriteAccess = {
  id: WriteAccessEnum;
  name: string;
};

const ACCESS_OPTIONS = Object.values(WriteAccessEnum).map((writeAccess) => {
  return { id: writeAccess, name: `common.writeAccess_${writeAccess}` };
});

function FormBoardWriteAccess({
  data,
  handleFieldChange,
}: {
  data: {
    isPublic: boolean;
    hasPassword: boolean;
    writeAccess: string;
    plainTextPassword: string;
  };
  handleFieldChange: (...args: any[]) => void;
}) {
  const [t] = useTranslation();

  const handleWriteAccessChange = useCallback(
    (event: WriteAccess) => {
      handleFieldChange(null, { name: 'writeAccess', value: event.id });
    },
    [handleFieldChange],
  );

  const selectedValue = useMemo(() => {
    return ACCESS_OPTIONS.filter((option) => option.id === data.writeAccess)[0]!;
  }, [data.writeAccess]);

  return (
    <div className="flex flex-col mt-7">
      <div>{t('common.writeAccessAddCards')}</div>
      <SingleSelectComboBox
        dataTestId="writeAccessComboBox"
        options={ACCESS_OPTIONS}
        getId={(option: WriteAccess) => option.id}
        selectedOption={selectedValue}
        setSelectedOption={handleWriteAccessChange}
        getLabel={(option: WriteAccess) => t(option.name)}
      />
    </div>
  );
}

export default React.memo(FormBoardWriteAccess);
