import { createSelector } from 'redux-orm';
import orm from '../orm';
import { isLocalId } from '../utils/local-id';

export const makeSelectListById = () =>
  createSelector(
    orm,
    (_, id) => id,
    // @ts-ignore
    ({ List }, id: string) => {
      // @ts-ignore
      const listModel = List.withId(id);

      if (!listModel) {
        return listModel;
      }

      return {
        ...listModel.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const selectListById = makeSelectListById();

const isInFuture = (dateString: string, now: number) => {
  const parsedDate = new Date(dateString).getTime();
  return parsedDate > now;
};

export const makeSelectCardIdsByListId = ({
  allowFuture = true,
  allowOnlyApproved = false,
}: { allowFuture?: boolean; allowOnlyApproved?: boolean } = {}) =>
  createSelector(
    orm,
    (_, id: string) => id,
    // @ts-ignore
    ({ List }, id) => {
      // @ts-ignore
      const listModel = List.withId(id);

      if (!listModel) {
        return listModel;
      }

      const cardModels = listModel.getFilteredOrderedCardsModelArray();
      const filteredCardModels = cardModels.filter((cardModel) => {
        if (allowOnlyApproved && !cardModel.approved) {
          return false;
        }

        if (allowFuture) {
          return true;
        }
        const visibleDate = cardModel.scheduledToBeVisibleAt;
        if (!visibleDate) {
          return true;
        }
        return !isInFuture(visibleDate, Date.now());
      });
      const ids = filteredCardModels.map((cardModel) => cardModel.id);
      return ids;
    },
  );

export const selectCardIdsForSlideshowByListId = makeSelectCardIdsByListId({
  // not in future and only show approved
  allowOnlyApproved: true,
  allowFuture: false,
});
