/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import ButtonType from '../../constants/ButtonType.js';
import InputCheckbox from '../../lib/custom-ui/components/Input/InputCheckbox';
import Button from '../Button/Button.js';
import FormBoardPassword from '../FormBoardPassword/FormBoardPassword.js';
import FormBoardAnonymousWriteAccess from '../FormBoardWriteAccess/FormBoardWriteAccess';
import FormError from '../FormError/FormError.js';
import FormUserAccess from '../FormUserAccess/FormUserAccess.js';

function BoardAccessModalContent({
  isSubmitting,
  handleSubmit,
  data,
  handleFieldChange,
  error,
  showBoardPassword,
}: {
  isSubmitting: boolean;
  handleSubmit: () => void;
  data: {
    isPublic: boolean;
    hasPassword: boolean;
    writeAccess: string;
    plainTextPassword: string;
    emails: readonly string[];
  };
  handleFieldChange: (...args: any[]) => void;
  error?: any;
  showBoardPassword?: boolean;
}) {
  const [t] = useTranslation();

  const setEmails = (emails: readonly string[]) => {
    handleFieldChange(null, {
      name: 'emails',
      value: emails,
    });
  };

  const compatPublic = !data.plainTextPassword && data.isPublic;

  return (
    <div className="bg-white text-blue100 p-8 pr-6 pb-6">
      <header className="flex items-center gap-2">
        <span className="text-2xl text-blue100 font-bold">
          {t('common.boardAccess', {
            context: 'title',
          })}
        </span>
      </header>

      <Form onSubmit={handleSubmit} className="mt-2">
        <label className="flex gap-1">
          <InputCheckbox
            id="isPublic"
            name="isPublic"
            data-testid="isPublic"
            checked={compatPublic}
            onChange={(event) => {
              const newChecked = event.target.checked;
              if (newChecked) {
                handleFieldChange(null, {
                  name: 'plainTextPassword',
                  value: '',
                });
              }
              handleFieldChange(null, {
                name: 'isPublic',
                value: newChecked,
              });
            }}
          />
          <span>{t('common.boardIsPublic')}</span>
        </label>

        {showBoardPassword && !compatPublic && (
          <FormBoardPassword data={data} handleFieldChange={handleFieldChange} />
        )}

        {compatPublic && (
          <FormBoardAnonymousWriteAccess data={data} handleFieldChange={handleFieldChange} />
        )}

        <FormUserAccess emails={data.emails} setUsers={setEmails} />

        <FormError error={error} />

        <div className="flex justify-end mt-5">
          <Button type="submit" disabled={isSubmitting} variant={ButtonType.Green}>
            {t('action.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default React.memo(BoardAccessModalContent);
