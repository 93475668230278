import * as MediaPreviewType from '../../constants/MediaPreviewType.js';
import MediaPreviewContainer from '../../containers/MediaPreviewContainer.js';
import { getMediaPreviewType } from '../../utils/getMediaPreviewType.js';
import MediaRemovePreviewImageButton from '../MediaRemovePreviewImageButton/MediaRemovePreviewImageButton.js';
import MediaUpload from '../MediaUpload/MediaUpload.js';
import MediaUploadRemoveButton from '../MediaUploadRemoveButton/MediaUploadRemoveButton.js';

const getRemoveButton = (
  customImageUpload: boolean,
  canEdit: boolean,
  defaultPreviewDeleted: boolean,
  mediaUrl: string,
  previewType: number,
): boolean => {
  if (
    customImageUpload &&
    (previewType === MediaPreviewType.EmbedGoogleMaps ||
      previewType === MediaPreviewType.EmbedInstagram ||
      previewType === MediaPreviewType.EmbedSoundcloud ||
      previewType === MediaPreviewType.EmbedVimeo ||
      previewType === MediaPreviewType.EmbedYoutube ||
      previewType === MediaPreviewType.EmbedYoutubeNoCookie ||
      previewType === MediaPreviewType.EmbedYoutubeShort)
  ) {
    return false;
  }
  return Boolean(customImageUpload && canEdit && (!defaultPreviewDeleted || mediaUrl));
};

function EmbedPreview({
  url,
  handleDelete,
  canEdit,
  ogImage,
  ogTitle,
  ogDescription,
  defaultPreviewDeleted,
  mediaUrl,
  onMediaUpdate,
  onImageDelete,
  isMediaUpdating,
  mediaLinkUrl,
  mediaPreviewUrl,
  customImageUpload,
  handleDeletePreviewImage,
}: {
  url: string;
  handleDelete: () => void;
  canEdit: boolean;
  ogImage: string;
  ogTitle: string;
  ogDescription: string;
  defaultPreviewDeleted: boolean;
  mediaUrl: string;
  onMediaUpdate: ({ file }: { file: File | null }) => void;
  onImageDelete: () => void;
  isMediaUpdating: boolean;
  mediaPreviewUrl?: string;
  mediaLinkUrl?: string;
  customImageUpload: boolean;
  handleDeletePreviewImage: () => void;
}) {
  const previewType = getMediaPreviewType(url, ogTitle);
  const showRemovePreviewImageButton = getRemoveButton(
    customImageUpload,
    canEdit,
    defaultPreviewDeleted,
    mediaUrl,
    previewType,
  );
  const showCustomImageUpload = customImageUpload && canEdit && defaultPreviewDeleted && !mediaUrl;
  const showMediaRemoveButton = !customImageUpload && canEdit;
  const handlePreviewFileSelect = () => {
    // noop
  };
  const handleFileDelete = () => {
    // noop
  };
  return (
    <div className="flex flex-col relative min-h-[200px]">
      <div className="flex flex-col items-center flex-1">
        {showCustomImageUpload && (
          <div>
            <MediaUpload
              media={mediaUrl}
              handleFileSelect={onMediaUpdate}
              handleFileDelete={onImageDelete}
              isMediaUpdating={isMediaUpdating}
              canEdit={canEdit}
              mediaPreviewUrl={mediaPreviewUrl}
              mediaLinkUrl={mediaLinkUrl}
              handlePreviewFileSelect={handlePreviewFileSelect}
              handlePreviewFileDelete={handleFileDelete}
            />
          </div>
        )}
        <div className="flex flex-col items-center p-4 w-full bg-white w-full contain-content">
          <MediaPreviewContainer
            url={url}
            ogTitle={ogTitle}
            ogDescription={ogDescription}
            ogImage={ogImage}
            defaultPreviewDeleted={defaultPreviewDeleted}
            mediaUrl={mediaUrl}
            mediaPreviewUrl={mediaPreviewUrl}
            customImageUpload={customImageUpload}
            imageId=""
          />
        </div>
      </div>
      {showRemovePreviewImageButton && (
        <MediaRemovePreviewImageButton handleDelete={handleDeletePreviewImage} />
      )}

      {showMediaRemoveButton && (
        <MediaUploadRemoveButton previewType={previewType} handleDelete={handleDelete} />
      )}
    </div>
  );
}

export default EmbedPreview;
