/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import React from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

function SingleSelectComboBox<T>({
  dataTestId,
  options,
  selectedOption,
  getLabel,
  getId,
  setSelectedOption,
  className,
}: {
  dataTestId?: string;
  options: readonly T[];
  selectedOption: T;
  getLabel: (option: T) => string;
  getId: (option: T) => string;
  setSelectedOption: (option: T) => void;
  className?: string;
}) {
  const dropDownOptions: DropdownItemProps[] = options.map((option) => {
    return {
      text: getLabel(option),
      key: getId(option),
      value: getId(option),
      onClick(event, data) {
        if (selectedOption === option) {
          return;
        }
        setSelectedOption(option);
        queueMicrotask(() => {
          if (document.activeElement instanceof HTMLInputElement) {
            document.activeElement.blur();
          }
        });
      },
    };
  });

  const controlledValue = getId(selectedOption);

  return (
    <div className={className}>
      <Dropdown
        data-testid={dataTestId}
        fluid
        selection
        options={dropDownOptions}
        value={controlledValue}
      />
    </div>
  );
}

export default React.memo(SingleSelectComboBox);
