/* eslint-disable jsx-a11y/media-has-caption */

import MediaPreviewFile from '../MediaPreviewFile/MediaPreviewFile.js';

function MediaPreviewVideo({
  url,
  interactive = true,
  minimal,
  media,
  mediaLinkUrl,
}: {
  url: string;
  interactive?: boolean;
  minimal?: boolean;
  media?: string;
  mediaLinkUrl?: string;
}) {
  const videoSrc = url || media || mediaLinkUrl;
  if (minimal) {
    return <MediaPreviewFile url={url} minimal />;
  }
  return (
    <div className="contain-content" data-testid="MediaPreviewVideo">
      <video src={videoSrc} controls={interactive} />
    </div>
  );
}

export default MediaPreviewVideo;
