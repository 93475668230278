import { getSupportedEmbedUrlMediaPreviewType } from './getSupportedEmbedUrlMediaPreviewType';
import { isImage } from './isImage';
import { isPdf } from './isPdf';
import { isSameHost } from './isSameHost';
import { isVideo } from './isVideo';
import * as MediaPreviewType from '../constants/MediaPreviewType';

export const getMediaPreviewType = (
  url: string,
  ogTitle?: string,
  customImageUpload?: boolean,
  minimal?: boolean,
  mediaLinkUrl?: string,
) => {
  const embedType = getSupportedEmbedUrlMediaPreviewType(url);
  if (embedType) {
    if (minimal) {
      return MediaPreviewType.File;
    }
    return embedType;
  }

  if (customImageUpload && ogTitle) {
    return MediaPreviewType.OpenGraph;
  }
  if (isImage(url)) {
    if (customImageUpload) {
      if (mediaLinkUrl && mediaLinkUrl !== url) {
        return MediaPreviewType.File;
      }
    }
    return MediaPreviewType.Image;
  }
  if (isVideo(url)) {
    return MediaPreviewType.Video;
  }
  if (isPdf(url)) {
    if (minimal) {
      return MediaPreviewType.Link;
    }
    return MediaPreviewType.Pdf;
  }

  if (ogTitle) {
    return MediaPreviewType.OpenGraph;
  }
  if (url) {
    if (isSameHost(url)) {
      return MediaPreviewType.File;
    }
    return MediaPreviewType.Link;
  }
  return MediaPreviewType.None;
};
