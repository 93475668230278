import { getPathName } from './getPathName.js';

const fixSpaces = (fileName: string) => {
  return fileName.replaceAll('%20', ' ');
};

export const getFileName = (url: string) => {
  if (!url) {
    return 'n/a';
  }
  const pathName = getPathName(url);
  const slashIndex = pathName.lastIndexOf('/');
  if (slashIndex === -1) {
    return pathName;
  }
  const fileName = pathName.slice(slashIndex + 1);
  const fixedFileName = fixSpaces(fileName);
  return fixedFileName;
};
