import { Loader } from 'semantic-ui-react';

function ImageUploadLoader({ customImageUpload }: { customImageUpload?: boolean } = {}) {
  return (
    <div className="flex flex-col bg-blue10 p-3 relative">
      <div className="flex flex-col items-center flex-1">
        <div className="flex flex-col items-center p-4 w-full contain-content bg-white">
          <div className="h-[200px] min-w-[200px]">
            <Loader size="medium" active inverted={!customImageUpload} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageUploadLoader;
